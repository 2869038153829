import {
  makeVar,
  InMemoryCache as ApolloInMemoryCache,
  defaultDataIdFromObject,
} from '@apollo/client';
import isClient from 'commons/isClient';
// eslint-disable-next-line import/no-unresolved
import { NextSSRInMemoryCache } from '@apollo/experimental-nextjs-app-support/ssr';
import isNewConsumerApp from 'commons/isNewConsumerApp';
import { getUserFromCookie } from '../commons/localStorage';

const InMemoryCache = isNewConsumerApp
  ? NextSSRInMemoryCache
  : ApolloInMemoryCache;

export default function createCache(config = {}) {
  const jbReactiveVars = isClient()
    ? {
        clearanceStockVar: makeVar({ loading: true }),
        closestStoreZipVar: makeVar({
          storeZip: null,
          loading: true,
          forUserZip: null,
        }),
      }
    : undefined;

  const cache = new InMemoryCache({
    ...config,
    freezeResults: true,
    dataIdFromObject: object => {
      switch (object.__typename) {
        case 'Option':
        case 'ExtendedOption':
          if (object.product_id) {
            return `${object.__typename}.${object.product_id}.${object.id}`;
          }
          return defaultDataIdFromObject(object);
        case 'OptionValue':
        case 'ExtendedOptionValue':
          if ('variant_id' in object && object.variant_id !== 0) {
            return `${object.__typename}.${object.id}.${object.variant_id}`;
          }
          return defaultDataIdFromObject(object);
        default:
          return defaultDataIdFromObject(object);
      }
    },
    typePolicies: {
      Query: {
        fields: {
          user: {
            read() {
              return getUserFromCookie() || null;
            },
          },
        },
      },
      Product: {
        fields: {
          meta: {
            merge(existing = {}, incoming = {}) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
      // Disable normalization for the types that are unique for each instance
      // of PLPProduct, so they are keyed by their parent instead
      PLPAttribute: {
        keyFields: false,
      },
      PLPRangeAttribute: {
        keyFields: false,
      },
      PLPSortOrder: {
        keyFields: false,
      },
      PLPVariant: {
        keyFields: false,
      },
      // Disable normalization for PLPProduct so that duplicate products
      // are not deduped, in cases like clearance page products
      PLPProduct: {
        keyFields: false,
      },
      // Disable normalization for the types that are unique for each instance
      // of ProductListingPage, so they are keyed by their parent instead
      PLPFilter: {
        keyFields: false,
      },
      PLPFilterOption: {
        keyFields: false,
      },
    },
  });

  cache.jbReactiveVars = jbReactiveVars;

  return cache;
}
