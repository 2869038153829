import GraphQLJSON from 'graphql-type-json';
import createInMemoryCache from './cache';
import productCardFeature from './product-card';

const features = {
  productcard: productCardFeature,
};

const queries = Object.values(features).reduce((acc, curr) => {
  if (curr) {
    return acc.concat(curr.Query);
  }

  return acc;
}, []);

const mutations = Object.values(features).reduce((acc, curr) => {
  if (curr) {
    return acc.concat(curr.Mutation);
  }

  return acc;
}, []);

const Query = Object.assign({}, ...queries);
const Mutation = Object.assign({}, ...mutations);

export const resolvers = {
  Query,
  Mutation,
  JSON: GraphQLJSON,
};

export const createCache = createInMemoryCache;

export default {
  resolvers,
  createCache,
};
